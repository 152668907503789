import React from "react";

import Layout from "../components/Layout";

const Page = () => {
  return (
    <Layout>
      <section className="pt-12 md:pt-16 pb-20 md:pb-30">
        <div className="container">
          <h1>Privacy Policy</h1>
          <p>
            We recognize that you may be concerned about our use and disclosure
            of your personal information. Your privacy is very important to us,
            and the following will inform you of the information that we,
            Jorgensen, Brownell &amp; Pepin, P.C., may collect from you, and how
            it is used. By using our website, www.jbplegal.com, you are
            accepting the practices described in this policy.
          </p>

          <h2>Information Collection</h2>
          <p>
            We may collect non-personal information, such as a domain name and
            IP Address. The domain name and IP address reveals nothing personal
            about you other than the IP address from which you have accessed our
            site. We may also collect information about the type of Internet
            browser you are using, operating system, what brought you to our
            Website, as well as which of our Web pages you have accessed.
          </p>
          <p>
            Additionally, if you communicate with us regarding our Website or
            our services, we will collect any information that you provide to us
            in any such communication.
          </p>
          <p>
            We may contact you via email in the future to tell you about
            specials, new products or services, or changes to this privacy
            policy.
          </p>

          <h2>Information Use</h2>
          <p>
            We use the collected information primarily for our own internal
            purposes, such as providing, maintaining, evaluating, and improving
            our services and Website, fulfilling requests for information, and
            providing customer support.
          </p>

          <h2>Consent</h2>
          <p>
            By using this Website, you consent to the collection and use of
            information as specified above. If we make changes to our Privacy
            Policy, we will post those changes on this page. Please review this
            page frequently to remain up-to-date with the information we
            collect, how we use it, and under what circumstances we disclose it.
            You must review the new Privacy Policy carefully to make sure you
            understand our practices and procedures.
          </p>
          <p>
            <strong>
              If you feel that we are not abiding by this privacy policy, you
              should contact us immediately via telephone at{" "}
            </strong>{" "}
            <strong>
              (720) 491-3117 or via mail Attn: Privacy Officer, 8001 Arista
              Place, Suite 415, Broomfield, CO 80021.
            </strong>
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default Page;
